import { useContext, useState } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import HeaderMenuComponent from './HeaderMenuComponent'

import checkBreakpoint from 'lib/checkBreakpoint'
import { getUser } from 'state/ducks/user/selectors'
import { StoreState } from 'state/ducks'
import { AuthContext } from 'providers/AuthProvider'

//type
import { Lang } from 'types/lang'

//hooks
import { getLangs, getLangByLocale } from 'hooks/common/lang'

import styles from './SideMenuComponent.module.scss'

interface Props {}

const SideMenuComponent: React.FC<Props> = (Props) => {
  const user = useSelector((state: StoreState) => getUser(state))
  const [isOpenAccordion, setIsOpenAccordion] = useState<boolean>(false)
  const { isSigned, userId } = useContext(AuthContext)
  const { asPath, locale } = useRouter()
  const { isMobile } = checkBreakpoint()
  const { t } = useTranslation()

  const langs: Lang[] = getLangs().filter((lang: Lang) => {
    return lang.slug !== locale
  })

  return (
    <div className={styles.sideMenu}>
      <nav>
        <ul className={styles.menu}>
          <li>
            <Link href="/locations">
              <a>
                <img src="/search_icon.svg" alt={t('META.SEARCH_ICON')} />
                {t('COMMON.SIDE_MENU.SEARCH')}
              </a>
            </Link>
          </li>
          {isSigned && (
            <>
              <li>
                <Link href="/bookings">
                  <a>
                    <img src="/booking_icon.svg" alt={t('META.BOOKING_ICON')} />
                    {t('COMMON.SIDE_MENU.BOOKING_LIST')}
                  </a>
                </Link>
              </li>
              <li>
                <Link href={`/users/${userId}`}>
                  <a>
                    <img src="/setting_icon.svg" alt={t('META.SETTING_ICON')} />
                    {t('COMMON.SIDE_MENU.ACCOUNT')}
                    {user && !user?.phone_verified && (
                      <img src="/warning.png" alt="" className={styles.warningIcon} />
                    )}
                  </a>
                </Link>
              </li>
            </>
          )}
          <li>
            <Link href="https://intercom.help/ecbocloak" passHref>
              <a target="_blank" rel="noreferrer">
                <img src="/faq_icon.svg" alt={t('META.FAQ_ICON')} />
                {t('COMMON.SIDE_MENU.QUESTION')}
              </a>
            </Link>
          </li>
          <li className={styles.openIntercom} onClick={() => window.Intercom('showMessages')}>
            <img src="/contact_icon.svg" alt={t('META.CONTACT_ICON')} />
            {t('COMMON.SIDE_MENU.CONTACT')}
          </li>
          {isMobile && (
            <>
              <li
                className={styles.accordionMenu}
                onClick={() => setIsOpenAccordion(!isOpenAccordion)}
              >
                <img
                  className={styles.accordionMenuLangIcon}
                  src="/language_icon.svg"
                  alt={t('META.LANGUAGE_ICON')}
                  height={24}
                  width={24}
                />
                <div className={styles.accordionMenuTitle}>{getLangByLocale(locale).name}</div>
                <img
                  className={`${styles.accordionMenuArrowIcon} ${
                    isOpenAccordion && styles.accordionMenuArrowIconActive
                  }`}
                  src="/triangle_bottom_icon.svg"
                  alt={t('META.LANGUAGE_ICON')}
                  width={12}
                  height={8}
                />
              </li>
              {isOpenAccordion && (
                <ul className={styles.subMenu}>
                  {langs.map((lang: Lang, index) => (
                    <li key={index}>
                      <Link href={asPath} locale={lang.slug} passHref>
                        <a className={styles.language}>{lang.name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </ul>
      </nav>
      <div className={styles.ownerMenu}>
        <Link href={`${process.env.AUTH_API_URL}/owners/sign_in`}>{t('META.OWNER_SIGN_IN')}</Link>
      </div>
      {isMobile && (
        <div className={styles.headerMenu}>
          <HeaderMenuComponent />
        </div>
      )}
    </div>
  )
}

export default SideMenuComponent
