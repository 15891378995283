import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'

//component
import HeaderMenuComponent from '../menus/HeaderMenuComponent'
import { getUser } from 'state/ducks/user/selectors'
import { StoreState } from 'state/ducks'

import styles from './HeaderComponent.module.scss'

interface Props {
  onPressHamburgerMenu: () => void
  isOpenMenu: boolean
}

const HeaderComponent: React.VFC<Props> = ({
  onPressHamburgerMenu,
  isOpenMenu,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const user = useSelector((state: StoreState) => getUser(state))

  return (
    <header className={`${styles.header} ${isOpenMenu ? styles.openSideMenu : ''}`}>
      <div className={styles.headerBox}>
        <div className={styles.headerLeft}>
          <div className={styles.headerLeftItem}>
            <div
              className={`${styles.hamburgerMenu} ${isOpenMenu ? styles.open : ''}`}
              onClick={onPressHamburgerMenu}
            >
              <span />
              <span />
              <span />
            </div>
            <div className={isOpenMenu ? styles.hideLogo : ''}>
              <Link href="/">
                <img
                  className={styles.logo}
                  src="/logo.webp"
                  alt={t('META.LOGO')}
                  height="30px"
                  width="130px"
                />
              </Link>
            </div>
          </div>
        </div>
        <nav className={styles.headerRight}>
          <HeaderMenuComponent />
        </nav>
      </div>
    </header>
  )
}

export default HeaderComponent
