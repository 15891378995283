import { createSelector } from 'reselect'
import { StoreState } from '..'

export const getUser = createSelector(
  ({ user }: StoreState) => user.data,
  (users) => users.result
)

export const getCreditCard = createSelector(
  ({ creditCard }: StoreState) => creditCard.data,
  (creditCard) => creditCard.result
)
