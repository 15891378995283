import { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

const AppStoreButtonComponent: FC = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Link href="https://itunes.apple.com/jp/app/ecbo-cloak/id1443707795">
      <img src="/app_store.webp" alt={t('META.APP_STORE')} width="120px" height="40px" />
    </Link>
  )
}

export default AppStoreButtonComponent
