import { useState } from 'react'
import Link from 'next/link'
import checkBreakpoint from 'lib/checkBreakpoint'
import styles from './FooterComponent.module.scss'
import AppStoreButtonComponent from '../../buttons/AppStoreButtonComponent'
import GooglePlayButtonComponent from '../../buttons/GooglePlayButtonComponent'
import AccordionIconComponent from '../../icons/AccordionIconComponent'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

const FooterComponent = () => {
  const { isMobile } = checkBreakpoint()
  const [isOpenAccordion, setIsOpenAccordion] = useState<{ open: boolean; target: string }>({
    open: false,
    target: '',
  })
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <footer className={styles.footer}>
      <div className={styles.footerHeading}>
        <img src="/logo_footer.webp" alt={t('META.LOGO_FOOTER')} width="161px" height="36px" />
      </div>
      <div className={styles.footerContents}>
        <div className={styles.list}>
          <div
            className={styles.listHeading}
            onClick={() =>
              isMobile ? setIsOpenAccordion({ open: !isOpenAccordion.open, target: 'service' }) : ''
            }
          >
            <h3 className={styles.listHeadingTitle}>{t('FOOTER.SERVICE.ABOUT')}</h3>
            <div className={styles.accordion}>
              <AccordionIconComponent isOpenAccordion={isOpenAccordion.open} position="footer" />
            </div>
          </div>
          {(!isMobile || (isOpenAccordion.open && isOpenAccordion.target === 'service')) && (
            <ul className={styles.listItems}>
              {/* TODO: 言語によって切り替える */}
              <li>
                <Link href="https://intercom.help/ecbocloak" passHref>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.SERVICE.QUESTION')}
                  </a>
                </Link>
              </li>
              <li className={styles.openIntercom} onClick={() => window.Intercom('showMessages')}>
                {t('FOOTER.SERVICE.CONTACT')}
              </li>
              <li>
                <Link href="https://assets.ecbo.io/" passHref>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.SERVICE.ASSET')}
                  </a>
                </Link>
              </li>
              {locale !== 'en' && (
                <li>
                  <Link
                    href={`https://join-cloak.ecbo.io/owner${locale !== 'ja' ? `/${locale}` : ''}`}
                    passHref
                  >
                    <a target="_blank" rel="noreferrer">
                      {t('FOOTER.SERVICE.OWNER_LP')}
                    </a>
                  </Link>
                </li>
              )}
            </ul>
          )}
        </div>
        <div className={styles.list}>
          <div
            className={styles.listHeading}
            onClick={() =>
              isMobile ? setIsOpenAccordion({ open: !isOpenAccordion.open, target: 'company' }) : ''
            }
          >
            <h3 className={styles.listHeadingTitle}>{t('FOOTER.COMPANY.ABOUT')}</h3>
            <div className={styles.accordion}>
              <AccordionIconComponent isOpenAccordion={isOpenAccordion.open} position="footer" />
            </div>
          </div>
          {(!isMobile || (isOpenAccordion.open && isOpenAccordion.target === 'company')) && (
            <ul className={styles.listItems}>
              <li>
                <Link href="https://ecbo.io/" passHref>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.COMPANY.SITE')}
                  </a>
                </Link>
              </li>
              {/* TODO: companyページコンポーネント作成後変更 */}
              <li>
                <Link href="https://cloak.ecbo.io/ja/company" passHref>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.COMPANY.INFOMATION')}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://www.wantedly.com/companies/ecbo/projects" passHref>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.COMPANY.CAREER')}
                  </a>
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className={styles.list}>
          <div
            className={styles.listHeading}
            onClick={() =>
              isMobile ? setIsOpenAccordion({ open: !isOpenAccordion.open, target: 'term' }) : ''
            }
          >
            <h3 className={styles.listHeadingTitle}>{t('FOOTER.TERM.ABOUT')}</h3>
            <div className={styles.accordion}>
              <AccordionIconComponent isOpenAccordion={isOpenAccordion.open} position="footer" />
            </div>
          </div>
          {(!isMobile || (isOpenAccordion.open && isOpenAccordion.target === 'term')) && (
            <ul className={styles.listItems}>
              {/* TODO: termページコンポーネント作成後変更(ドメイン違うけど、揃えた方がいいかも？) */}
              <li>
                <Link href="/terms">{t('FOOTER.TERM.INFOMATION')}</Link>
              </li>
              {/* TODO: privacyページコンポーネント作成後変更 */}
              <li>
                <Link
                  href={`https://cloak.ecbo.io/${locale !== 'ja' ? `${locale}` : 'ja'}/privacy`}
                >
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.TERM.PRIVACY')}
                  </a>
                </Link>
              </li>
              {/* TODO: legalページコンポーネント作成後変更 */}
              <li>
                <Link href={`https://cloak.ecbo.io/${locale !== 'ja' ? `${locale}` : 'ja'}/legal`}>
                  <a target="_blank" rel="noreferrer">
                    {t('FOOTER.TERM.LEGAL')}
                  </a>
                </Link>
              </li>
            </ul>
          )}
        </div>
        <div className={styles.listOther}>
          <div className={styles.listOtherHeading}>
            <h3 className={styles.listOtherHeadingTitle}>{t('FOOTER.SNS.ABOUT')}</h3>
          </div>
          <ul className={styles.listOtherSnsItems}>
            <li className={styles.snsIcon}>
              <Link href={`https://www.facebook.com/${locale == 'zh-TW' ? 'ecbocloakTC' : 'ecbocloak'}`} passHref>
                <a target="_blank" rel="noreferrer">
                  <img
                    src="/facebook_icon.svg"
                    alt={t('META.FACEBOOK_ICON')}
                    height={24}
                    width={24}
                  />
                </a>
              </Link>
            </li>
            <li className={styles.snsIcon}>
              <Link href="https://twitter.com/ecbocloak" passHref>
                <a target="_blank" rel="noreferrer">
                  <img
                    src="/twitter_icon.svg"
                    alt={t('META.TWITTER_ICON')}
                    height={24}
                    width={24}
                  />
                </a>
              </Link>
            </li>
            <li className={styles.snsIcon}>
              <Link href="https://www.instagram.com/ecbocloak/" passHref>
                <a target="_blank" rel="noreferrer">
                  <img
                    src="/instagram_icon.svg"
                    alt={t('META.INSTAGRAM_ICON')}
                    height={24}
                    width={24}
                  />
                </a>
              </Link>
            </li>
            <li className={styles.snsIcon}>
              <Link href="https://www.youtube.com/channel/UCUTh2d0BlStbzEIaA4iZ6Qg/videos" passHref>
                <a target="_blank" rel="noreferrer">
                  <img
                    src="/youtube_icon.svg"
                    alt={t('META.YOUTUBE_ICON')}
                    height={24}
                    width={24}
                  />
                </a>
              </Link>
            </li>
          </ul>
          <ul className={styles.listOtherStoreItems}>
            <li>
              {/*
                TODO: adjustを使用するかしないかで実装が変わる
                      使うパターンは、adjustのリンクで統一する
                      使わないにしても、言語によってappleは切り替えを行う
                      googleplayはそのまま
              */}
              <AppStoreButtonComponent />
            </li>
            <li>
              <GooglePlayButtonComponent />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default FooterComponent
