import Head from 'next/head'

interface Props {
  title: string
  description: string
  image?: string
  url: string
  isCrawling?: boolean
  isCrawlingNoIndex?: boolean
  jsonLd?: string
}

const Meta = ({
  title,
  description,
  image,
  url,
  isCrawling,
  isCrawlingNoIndex,
  jsonLd,
}: Props): JSX.Element => {
  // URLを修正する
  url = url.replace('/zh_tw', '/zh-TW')

  const langs = {
    ja: 'ja',
    en: 'en',
    'zh-TW': 'zh-Hant',
  }

  // 本番環境外の場合は強制的にnoindex
  if (process.env.DOMAIN !== 'https://cloak.ecbo.io') {
    isCrawling = true
  }

  /**
   * 言語コードを取得する
   * @returns
   */
  const getAlternateTag = (lang: string): any => {
    // let langCode: string = 'ja'
    let langUrl: string = `${process.env.DOMAIN}${url}`

    // replace
    langUrl = langUrl
      .replace('/ja', `/${lang}`)
      .replace('/en', `/${lang}`)
      .replace('/zh-TW', `/${lang}`)

    return {
      langCode: langs[lang],
      langUrl,
    }
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:card" content={image ? 'summary' : 'summary_large_image'} />
      <meta name="twitter:site" content="@ecbocloak" />
      <meta property="og:url" content={`${process.env.DOMAIN}${url}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image ? image : `${process.env.DOMAIN}/ogp.png`} />
      {isCrawlingNoIndex && !isCrawling && <meta name="robots" content="noindex" />}
      {isCrawling && <meta name="robots" content="noindex, nofollow" />}
      <link rel="canonical" href={`${process.env.DOMAIN}${url}`} />
      <link rel="shortcut icon" href="/favicon.svg" key="shortcutIcon" type="image/svg+xml" />

      {Object.keys(langs).map((lang) => {
        const { langCode, langUrl } = getAlternateTag(lang)
        return <link key={lang} rel="alternate" hrefLang={langCode} href={langUrl} />
      })}

      {jsonLd && (
        <script
          key="json-ld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: jsonLd }}
        />
      )}

      {/* Change the file format to .png */}
      {/* <link rel="apple-touch-icon" href={'/favicon.svg'} /> */}
    </Head>
  )
}

export default Meta
