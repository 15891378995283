import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 992 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 991, min: 576 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
}

export default function checkBreakpoint() {
  const [isClient, setIsClient] = useState(false)
  const isDesktop = useMediaQuery({ minWidth: '992px' })
  const isTablet = useMediaQuery({
    minWidth: '576px',
    maxWidth: '991px',
  })
  const isMobile = useMediaQuery({ maxWidth: '575px' })

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true)
  }, [])

  return {
    isDesktop: isClient ? isDesktop : true,
    isTablet: isClient ? isTablet : false,
    isMobile: isClient ? isMobile : false,
  }
}
